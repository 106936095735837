.navbar {
  background-color: #396938;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  z-index: 5;

}

.nav-menu {
  background-color: #396938;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: -2.5%;
  left: -100%;
  z-index: 5;
  transition: 850ms;
}

.nav-menu.active {
  left: -2.6%;
  z-index: 5;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  z-index: 5;

}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  z-index: 5;

}

.nav-text a:hover {
  background-color: #1f3a1e;
  z-index: 5;

}

.nav-menu-items {
  width: 100%;
  z-index: 5;
}

.navbar-toggle {
  background-color: #1f3a1e;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;

}

span {
  margin-left: 16px;
}

.mainname {
  padding-left: 20px;
  font-weight: bold;
      color: white;
}

.back-button {
  background-color: white; /* Fondo blanco */
  color: black; /* Texto negro */
  border: none; /* Sin borde */
  margin-left: 20px; /* Espacio a la derecha para separar del contenido anterior */
  padding: 10px 20px; /* Ajusta el tamaño del botón ajustando el valor de padding */
  font-size: 16px; /* Ajusta el tamaño del texto del botón */
  border-radius: 5px; /* Agrega esquinas redondeadas al botón */
  cursor: pointer; /* Cambia el cursor al pasar el ratón sobre el botón */
}
